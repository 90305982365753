import axios from 'axios';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API_URI } from 'src/config';

const fetchPharmacy = createAsyncThunk(
  'pharmacy/fetchPharmacy',
  async (_, { getState }) => {
    const { loaded, data } = getState();

    if (!loaded) {
      return axios
        .get(`${API_URI}/pharmacy.json`)
        .then((response) => response.data);
    }

    return data;
  },
);

const pharmacySlice = createSlice({
  name: 'pharmacy',
  initialState: {
    pending: false,
    error: null,
    loaded: false,
    data: {},
  },
  reducers: {
    // REGULAR REDUCERS
  },
  extraReducers: {
    [fetchPharmacy.pending]: (state) => {
      state.pending = true;
    },
    [fetchPharmacy.rejected]: (state, action) => {
      state.error = action.error.message;
      state.pending = false;
    },
    [fetchPharmacy.fulfilled]: (state, action) => {
      state.data = action.payload;
      state.pending = false;
      state.loaded = true;
    },
  },
});

export { fetchPharmacy };
export default pharmacySlice.reducer;
