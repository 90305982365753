import { combineReducers } from 'redux';
// import sessionReducer from './sessionSlice';
import globalReducer from './globalSlice';
import homepageReducer from './homepageSlice';
import pharmacyReducer from './pharmacySlice';
import showcaseReducer from './showcaseSlice';
import userReducer from './userSlice';

const rootReducer = combineReducers({
  // session: sessionReducer, // disabled as it was only being used by chat plugin
  global: globalReducer,
  homepage: homepageReducer,
  pharmacy: pharmacyReducer,
  showcase: showcaseReducer,
  user: userReducer,
});

export default rootReducer;
