import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { hashSync, compareSync } from 'bcryptjs';
import PropTypes from 'prop-types';
import { fetchHomepage } from '../../store/slices/homepageSlice';
import Loader from '../Loader/Loader';
import Style from './scss/PasswordGate.module.scss';

const generateHash = (password) => {
  return hashSync(password, 10);
};

const hasAccess = ({ username, password, accessList }) => {
  return accessList.some((credential) => {
    const [credentialUsername, credentialPassword] = credential.split(
      ':',
    );

    return (
      credentialUsername === username &&
      compareSync(password, credentialPassword)
    );
  });
};

const PasswordGate = ({ children }) => {
  const accessList = [
    'helios:$2a$10$G2AUSpYk1j8a8XQxTbYWROquS5h3s2UzptI95Sd8vo45GdzQWPWmS',
  ];

  const [authenticated, setAuthenticated] = useState(false);

  const onSuccess = (credentials) => {
    sessionStorage.setItem(
      'credentials',
      JSON.stringify(credentials),
    );
    setAuthenticated(true);
  };

  useEffect(() => {
    if (!authenticated) {
      const credentials = sessionStorage.getItem('credentials');

      if (credentials) {
        const { username, password } = JSON.parse(credentials);

        if (hasAccess({ username, password, accessList })) {
          setAuthenticated(true);
        }
      }
    }
  });

  if (!authenticated) {
    return (
      <AuthenticationForm
        accessList={accessList}
        onSuccess={onSuccess}
      />
    );
  }

  return children;
};

const AuthenticationForm = ({ accessList, onSuccess }) => {
  const dispatch = useDispatch();
  const {
    data: { shared: global },
    pending: globalPending,
    loaded: globalLoaded,
  } = useSelector((state) => state.global);
  const { data, pending, loaded } = useSelector(
    (state) => state.homepage,
  );

  // eslint-disable-next-line no-unused-vars
  const [username, setUsername] = useState('helios'); // remove default to re-enable username
  const [password, setPassword] = useState();
  const [invalidPassword, setInvalidPassword] = useState(false);

  const onSubmit = (event) => {
    event.preventDefault();
    if (hasAccess({ username, password, accessList })) {
      onSuccess({ username, password });
    } else {
      setInvalidPassword(true);
    }
  };

  useEffect(() => {
    if (!loaded) {
      dispatch(fetchHomepage());
    }
  }, []);

  if (pending || globalPending || !loaded || !globalLoaded) {
    return <Loader />;
  }

  return (
    <div className={Style.PasswordGateContainer}>
      <div className={Style.PasswordGate}>
        <h1 className={Style.Header}>
          {global.password_gate_header_copy}
        </h1>
        <h2 className={Style.Subheader}>
          {global.password_gate_subheader_copy}
        </h2>
        <form
          className={Style.PasswordForm}
          action=""
          onSubmit={onSubmit}
        >
          {/*
        <label htmlFor="username">
          Username:&nbsp;
          <input
            type="text"
            id="username"
            onChange={(e) => setUsername(e.target.value)}
          />
        </label>
        */}
          <div className={Style.PasswordRow}>
            <input
              type="password"
              placeholder="Password"
              className={Style.PasswordInput}
              onChange={(e) => setPassword(e.target.value)}
            />
            <button
              type="submit"
              className={[Style.Button, Style.ButtonRed]
                .filter((c) => c)
                .join(' ')}
            >
              Enter
            </button>
          </div>
          {invalidPassword && (
            <div className={Style.ErrorMessage}>Invalid Password</div>
          )}
        </form>
      </div>
      <div className={Style.BackgroundGraphic}>
        <img src={data.background_image} alt="" />
      </div>
    </div>
  );
};

AuthenticationForm.propTypes = {
  accessList: PropTypes.array.isRequired,
  onSuccess: PropTypes.func.isRequired,
};

export default PasswordGate;
export { generateHash };
