module.exports = {
  PROJECT_NAME: 'Cardinal Health Solution Showcase',
  PHARMACY_TITLE_FULL:
    'Consumer Health Product Ordering, Front-end Solutions and B2C Digital Solutions',
  PHARMACY_TITLE_SHORT: 'Pharmacy',
  SHOWCASE_TITLE_FULL: 'Cardinal Health Solution Showcase',
  SHOWCASE_TITLE_SHORT: 'Showcase',
  OEP_REDIRECT_TO_URI:
    process.env.REACT_APP_OEP_REDIRECT_TO_URI || '',
  OEP_REDIRECT_FROM_URI:
    process.env.REACT_APP_OEP_REDIRECT_FROM_URI || '',
  PERENSO_URI:
    'https://oepauthorchestrationservice.azurewebsites.net/api/' +
    'CardinalHealthAuthOrchestration',
  PERENSO_REDIRECT_URI: 'https://rbc2021.onlineeventpro.freeman.com',
  PERENSO_SECURITY_KEY: '8bf41b61-0250-4eaf-ab7f-f25c27eb4dbf',
  PERENSO_API_KEY: 'A2CD490F-9001-43C7-856F-614B8552A647',
  PORT: process.env.PORT || 9000,
  MOBILE_BREAKPOINT: process.env.MOBILE_BREAKPOINT || 1024,
  API_URI: process.env.REACT_APP_API_URI || '',
  AUTH_API_URI: process.env.REACT_APP_AUTH_API_URI || '',
  ANALYTICS_API_URI: process.env.REACT_APP_ANALYTICS_API_URI || '',
  SUMOLOGIC_URI:
    process.env.SUMOLOGIC_URI ||
    'https://endpoint3.collection.us2.sumologic.com/receiver/v1/http/' +
      'ZaVnC4dhaV1oIJkpNr7yt3dtmWFnXbf_FgE4x47KOs_NOKx_KKMG4O7wzgqixPG5' +
      '_wg9NXBgp5polT4E0f4TCz37HCHqswaCCZrwB0SHZrAjP9IamdBLWw==',
};
