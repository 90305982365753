import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Analytics from '../Analytics/Analytics';
import Style from './scss/Footer.module.scss';

const Footer = () => {
  const location = useLocation();

  const {
    data: { footer },
    loaded,
  } = useSelector((state) => state.global);

  const links = useMemo(() => {
    const footerLinks = [];

    if (footer) {
      footerLinks.push({
        name: footer.legal_link_text,
        href: footer.legal_link_url,
        componentPageId: 'Terms of Use',
      });

      footerLinks.push({
        name: footer.privacy_policy_link_text,
        href: footer.privacy_policy_link_url,
        componentPageId: 'Privacy Policy',
      });
    }

    return footerLinks;
  }, [loaded]);

  if (!loaded) {
    return null;
  }

  return (
    <footer className={Style.Footer}>
      <div className={Style.FooterContainer}>
        <ul className={Style.FooterCopyright}>
          <li>{footer.copyright_text}</li>
        </ul>
        <nav className={Style.FooterNav} aria-labelledby="ftr_label">
          <h2 id="ftr_label" className="sr-only">
            Footer Navigation Links
          </h2>
          <ul className={Style.FooterNavLinks}>
            {links.map((link) => (
              <li key={link.name}>
                <Analytics
                  pageName={location.pathname}
                  navId="Footer"
                  componentPageId={link.componentPageId}
                  componentType="External Link"
                  url={link.href}
                >
                  <a
                    title={link.name}
                    href={link.href}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {link.name}
                    <span className="sr-only">
                      (opens in a new tab)
                    </span>
                  </a>
                </Analytics>
              </li>
            ))}
          </ul>
        </nav>
      </div>
    </footer>
  );
};

export default Footer;
