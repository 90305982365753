import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';
import { ANALYTICS_API_URI } from 'src/config';

// NOTE: you must send in the user object when using this function manually
const sendAnalytics = ({
  user,
  pageName,
  navId,
  componentPageId,
  componentType,
  url,
  customAttributes = {},
}) => {
  if (!user) return;

  let packedAttributes = {};

  // custom_attributes must be serialized as a string, and each field must also be a string
  if (Object.keys(customAttributes).length > 0) {
    for (const key of Object.keys(customAttributes)) {
      packedAttributes[key] = `${customAttributes[key]}`;
    }

    packedAttributes = JSON.stringify(packedAttributes);
  } else {
    packedAttributes = '-n/a-';
  }

  const payload = {
    timestamp: new Date().toISOString(),
    fuzion_attendee_id: user.fuzion_attendee_id || '-n/a-',
    registration_number: user.registration_number || '-n/a-',
    // first_name: user.firstname || '-n/a-',
    // last_name: user.lastname || '-n/a-',
    // email: user.email || '-n/a-',
    // user_name: '-n/a-',
    // contact_type: '-n/a-',
    // address_line_one: '-n/a-',
    // address_line_two: '-n/a-',
    // city: '-n/a-',
    // postal_code: '-n/a-',
    // state_province: '-n/a-',
    // country: user.countryId || '-n/a-',
    custom_attributes: packedAttributes,
    // video_attributes: '-n/a-',
    // company: user.companyname || '-n/a-',
    // phone_number: '-n/a-',
    // title: user.jobtitle || '-n/a-',
    // attendee_type_flag: ,
    // occupation_type: ,
    // registration_method_flag: ,
    // fuzion_exhibitor_id: ,
    // badge_number: user.badgeId || '-n/a-',
    nav_id: navId || '-n/a-',
    // nav_cookies: ,
    // nav_lang: ,
    // nav_plat: ,
    // nav_appv: ,
    // nav_agent: ,
    // nav_pathname: ,
    page: pageName || '-n/a-',
    component_page_id: componentPageId || '-n/a-',
    component_type: componentType || '-n/a-',
    component_url: url || '-n/a-',
    // clocked: ,
    source: 'Helios',
    // leaderboard_identifier: '-n/a-',
    // leaderboard_opt_in: '-n/a-',
  };

  if (
    process.env.NODE_ENV === 'development' ||
    process.env.REACT_APP_CONSOLE_DEBUG
  ) {
    console.log(`Analytics event: ${componentPageId} on ${navId}`);
    console.log('Analytics payload:', payload);
  }

  axios
    .post(`${ANALYTICS_API_URI}/analytics`, { ea: payload })
    .then((response) => {
      if (
        process.env.NODE_ENV === 'development' ||
        process.env.REACT_APP_CONSOLE_DEBUG
      ) {
        console.log('Analytics API response:', response);
      }
    })
    .catch((error) => {
      if (
        process.env.NODE_ENV === 'development' ||
        process.env.REACT_APP_CONSOLE_DEBUG
      ) {
        console.log('Analytics API error:', error);
      }
    });
};

const Analytics = ({
  children,
  pageName,
  navId,
  componentPageId,
  componentType,
  url,
  customAttributes,
}) => {
  const { data: user } = useSelector((state) => state.user);

  const data = {
    user,
    pageName,
    navId,
    componentPageId,
    componentType,
    url,
    customAttributes,
  };

  // ensure there is only one child element
  React.Children.only(children);

  // overload onclick function of child element
  let _onClick = () => {
    sendAnalytics(data);
  };

  if (children.props.onClick) {
    _onClick = (event) => {
      children.props.onClick(event);
      sendAnalytics(data);
    };
  }

  return React.cloneElement(children, { onClick: _onClick });
};

Analytics.propTypes = {
  children: PropTypes.node.isRequired,
  pageName: PropTypes.string,
  navId: PropTypes.string,
  componentPageId: PropTypes.string,
  componentType: PropTypes.string,
  url: PropTypes.string,
  customAttributes: PropTypes.object,
};

export { sendAnalytics };
export default Analytics;
