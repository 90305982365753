import SumoLogger from 'sumo-logger';
import { SUMOLOGIC_URI, PROJECT_NAME } from '../config';

const opts = {
  endpoint: SUMOLOGIC_URI,
  sourceName: PROJECT_NAME,
  clientUrl: (window && window.location.href) || '',
};

// log results to console during development
if (process.env.NODE_ENV === 'development') {
  opts.onSuccess = () => {
    console.log('sumologic log successful');
  };
  opts.onError = () => {
    console.log('sumologic log failure');
  };
}

const logger = new SumoLogger(opts);

if (typeof window !== 'undefined') {
  window.addEventListener('beforeunload', () => logger.flushLogs());
}

// usage: logger.log('...')
export default logger;
