import React, { useEffect } from 'react';
import {
  Route,
  Switch,
  Redirect,
  useHistory,
  useLocation,
} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import ContentWrapper from '../ContentWrapper/ContentWrapper';
import Loader from '../Loader/Loader';
// import {
//   OEP_REDIRECT_TO_URI,
//   OEP_REDIRECT_FROM_URI,
// } from '../../config';
import { fetchGlobal } from '../../store/slices/globalSlice';
import routes from './routes';
import { clearUser, setUser } from '../../store/slices/userSlice';

const ExternalRedirect = ({ to }) => {
  window.location.replace(to);
  return null;
};

ExternalRedirect.propTypes = {
  to: PropTypes.string.isRequired,
};

const App = () => {
  const dispatch = useDispatch();

  // users will be redirected from OEP site with url query parameters:
  // ?fuzion_attendee_id=118a77dac7897196cc8a8c6b399bd0f8
  //    &custom_attribute_canOrder=true&registration_number=12345
  const location = useLocation();
  const queries = new URLSearchParams(location.search);
  const history = useHistory();

  const { isAuthenticated, pending } = useSelector(
    (state) => state.user,
  );

  useEffect(() => {
    dispatch(fetchGlobal());

    // check url query for expected user information
    if (queries && queries.has('fuzion_attendee_id')) {
      dispatch(
        setUser({
          fuzion_attendee_id: queries.get('fuzion_attendee_id'),
          registration_number: queries.get('registration_number'),
          custom_attribute_canOrder: queries.has(
            'custom_attribute_canOrder',
          )
            ? queries
                .get('custom_attribute_canOrder')
                .toLowerCase() === 'true'
            : false,
        }),
      );

      // remove url query string from url
      history.replace(location.pathname);
    } else {
      dispatch(clearUser());
    }
  }, []);

  return (
    <Switch>
      {routes.map((route) => (
        <Route
          key={route.name}
          path={route.path}
          exact={route.exact}
          render={() => {
            const { isAuth = false } = route.params || {};

            // need to reset scroll position in between page change
            if (typeof window !== 'undefined') {
              window.scrollTo(0, 0);
            }

            if (!isAuth || (isAuth && isAuthenticated)) {
              return (
                <ContentWrapper>
                  <route.component key={Date.now()} />
                </ContentWrapper>
              );
            }

            if (isAuth && pending) {
              return <Loader />;
            }

            return (
              <Redirect
                to={{
                  pathname: '/',
                  state: { authenticated: false },
                }}
              />
            );

            // unauthenticated: redirect to OEP login page
            // return (
            //   <ExternalRedirect
            //     to={encodeURI(
            //       `${OEP_REDIRECT_TO_URI}?redirect_uri=${OEP_REDIRECT_FROM_URI}`,
            //     )}
            //   />
            // );
          }}
        />
      ))}
    </Switch>
  );
};

export default App;
