import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import Analytics from '../Analytics/Analytics';
// import { perensoLink } from '../../util/perenso';
import { PROJECT_NAME } from '../../config';
import Style from './scss/Header.module.scss';

const Header = () => {
  const location = useLocation();

  const {
    data: { header },
    loaded,
  } = useSelector((state) => state.global);

  const { /* data: user, */ isAuthenticated } = useSelector(
    (state) => state.user,
  );

  const links = useMemo(() => {
    const headerLinks = [];

    if (header) {
      // if (user.custom_attribute_canOrder === true) {
      //   headerLinks.push({
      //     name: header.product_ordering_link_text,
      //     href: perensoLink({
      //       attendeeId: user.registration_number,
      //       pageKey: header.product_ordering_link_url,
      //     }),
      //     showOnPathname: '/Pharmacy', // optional, the path that this <li> is shown on
      //     linkType: 'button',
      //     componentPageId: 'Product Ordering',
      //   });
      // }
      // headerLinks.push({
      //   name: header.oep_homepage_link_text,
      //   href: header.oep_homepage_link_url,
      //   linkType: 'link',
      //   componentPageId: 'OEP Homepage',
      // });
    }

    return headerLinks;
  }, [loaded, isAuthenticated]);

  if (!loaded) {
    return null;
  }

  return (
    <header className={Style.Header} role="banner">
      <nav className={Style.HeaderNav} aria-labelledby="hdr_label">
        <h2 id="hdr_label" className="sr-only">
          Header Navigation
        </h2>
        <ul className={Style.HeaderNavLinks}>
          <li className={Style.HeaderNavLogo}>
            <Analytics
              pageName={location.pathname}
              navId="Header"
              componentPageId="Logo Back Button"
              componentType="External Link"
              url={window.location.href}
            >
              <Link title={`${PROJECT_NAME} Home`} to="/">
                <img
                  src={header.header_logo_image}
                  alt={`${PROJECT_NAME} Logo`}
                  className={Style.Logo}
                />
              </Link>
            </Analytics>
          </li>
          {links.map((link) => (
            <React.Fragment key={link.name}>
              {link.showOnPathname &&
              link.showOnPathname !== location.pathname ? null : (
                <li
                  className={
                    link.linkType === 'button'
                      ? Style.HeaderNavButton
                      : Style.HeaderNavLink
                  }
                >
                  <Analytics
                    pageName={location.pathname}
                    navId="Header"
                    componentPageId={link.componentPageId}
                    componentType={
                      link.linkType === 'button'
                        ? 'Button'
                        : 'External Link'
                    }
                    url={link.href}
                  >
                    <a
                      title={link.name}
                      href={link.href}
                      className={
                        link.linkType === 'button'
                          ? `${Style.Button} ${Style.ButtonRed}`
                          : Style.Link
                      }
                      target="_blank"
                      rel="noreferrer"
                    >
                      {link.name}
                      <span className="sr-only">
                        (opens in a new tab)
                      </span>
                    </a>
                  </Analytics>
                </li>
              )}
            </React.Fragment>
          ))}
        </ul>
      </nav>
    </header>
  );
};

export default Header;
