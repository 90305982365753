import axios from 'axios';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AUTH_API_URI } from 'src/config';

const fetchUser = createAsyncThunk(
  'user/fetchUser',
  async (id, { getState }) => {
    const { user } = getState();

    // don't do anything if we've already authenticated
    if (user.isAuthenticated) {
      return user.data;
    }

    // TODO: finish integration with auth verification
    return axios.get(AUTH_API_URI).then((response) => {
      if (response.data && response.status === 200) {
        return response.data;
      }

      throw new Error(
        `Unable to authenticate user: ${response.data}`,
      );
    });
  },
);

const userSlice = createSlice({
  name: 'user',
  initialState: {
    isAuthenticated: false,
    pending: true,
    error: null,
    data: {},
  },
  reducers: {
    setUser(state, action) {
      state.data = action.payload;
      state.isAuthenticated = true;
      state.pending = false;

      if (
        process.env.NODE_ENV === 'development' ||
        process.env.REACT_APP_CONSOLE_DEBUG
      ) {
        console.log('Set user:', action.payload);
      }
    },
    clearUser(state) {
      state.isAuthenticated = false;
      state.pending = false;
      state.data = {};
    },
  },
  extraReducers: {
    [fetchUser.pending]: (state) => {
      state.pending = true;
    },
    [fetchUser.rejected]: (state, action) => {
      state.error = action.error.message;
      state.pending = false;
    },
    [fetchUser.fulfilled]: (state, action) => {
      state.data = action.payload;
      state.isAuthenticated = true;
      state.pending = false;

      if (
        process.env.NODE_ENV === 'development' ||
        process.env.REACT_APP_CONSOLE_DEBUG
      ) {
        console.log('Fetched user:', action.payload);
      }
    },
  },
});

export { fetchUser };
export const { setUser, clearUser } = userSlice.actions;
export default userSlice.reducer;
