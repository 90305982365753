import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTransition, animated, config } from '@react-spring/web';
import parse from 'html-react-parser';
import PropTypes from 'prop-types';
import useCookie from '../../hooks/useCookie';
import Analytics from '../Analytics/Analytics';
import Style from './scss/CookieNotice.module.scss';

const CookieNotice = ({ disableAnimation }) => {
  const location = useLocation();

  const {
    data: { shared: global },
  } = useSelector((state) => state.global);

  const [acceptedCookie, setAcceptedCookie] = useCookie(
    'acceptedCookiePolicy',
    'no',
  );

  let closeTransition;

  if (!disableAnimation) {
    closeTransition = useTransition(acceptedCookie === 'no', {
      // from: {
      //   height: '3.75rem',
      // },
      enter: {
        height: '3.75rem',
        // opacity: 1,
      },
      leave: {
        padding: 0,
        border: 0,
        height: '0rem',
        // opacity: 0,
      },
      config: config.gentle,
    });
  }

  if (!global || global.cookie_consent_popup_text.length <= 0) {
    return null;
  }

  if (disableAnimation) {
    return (
      <>
        {acceptedCookie === 'no' && (
          <div className={Style.CookieNotice}>
            <Analytics
              pageName={location.pathname}
              navId="Home Page"
              componentPageId="Cookie Consent"
              componentType="Button"
              url={window.location.href}
            >
              <button
                type="button"
                aria-label="Close Cookie Notice"
                className={Style.CookieNoticeClose}
                onClick={(e) => {
                  e.stopPropagation();
                  setAcceptedCookie('yes', 30);
                }}
              />
            </Analytics>
            <div>{parse(global.cookie_consent_popup_text)}</div>
          </div>
        )}
      </>
    );
  }

  return (
    <>
      {closeTransition(
        (styles, item) =>
          item && (
            <animated.div
              className={Style.CookieNotice}
              style={styles}
            >
              {acceptedCookie === 'no' && (
                <>
                  <Analytics
                    pageName={location.pathname}
                    navId="Home Page"
                    componentPageId="Cookie Consent"
                    componentType="Button"
                    url={window.location.href}
                  >
                    <button
                      type="button"
                      aria-label="Close Cookie Notice"
                      className={Style.CookieNoticeClose}
                      onClick={(e) => {
                        e.stopPropagation();
                        setAcceptedCookie('yes', 30);
                      }}
                    />
                  </Analytics>
                  <div>{parse(global.cookie_consent_popup_text)}</div>
                </>
              )}
            </animated.div>
          ),
      )}
    </>
  );
};

CookieNotice.propTypes = {
  disableAnimation: PropTypes.bool,
};

export default CookieNotice;
