import { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import parse from 'html-react-parser';
import { fetchHomepage } from '../../store/slices/homepageSlice';
import Loader from '../Loader/Loader';
import ErrorPage from '../Error/ErrorPage';
// import WelcomeVideo from '../WelcomeVideo/WelcomeVideo';
import Analytics from '../Analytics/Analytics';
import Style from './scss/HomePage.module.scss';

const HomePage = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const {
    // data: global,
    pending: globalPending,
    loaded: globalLoaded,
    error: globalError,
  } = useSelector((state) => state.global);
  const { data, pending, loaded, error } = useSelector(
    (state) => state.homepage,
  );

  useEffect(() => {
    if (!loaded) {
      dispatch(fetchHomepage());
    }
  }, []);

  if (error || globalError) {
    return (
      <ErrorPage
        error={error || globalError}
        location={window.location}
      />
    );
  }

  if (pending || globalPending || !loaded || !globalLoaded) {
    return <Loader />;
  }

  return (
    <div className={Style.Home}>
      <div className={Style.HomeTitleContainer}>
        <h1>{data.welcome_message_text}</h1>
        <h2>{data.welcome_message_sub_text}</h2>
      </div>
      {/* <WelcomeVideo
        autoplay
        controls
        skipButton
        delayLoading={1250}
      /> */}
      <div className={Style.ScenesContainer}>
        {data.environment_homepage_info.map((scene) => (
          <div key={scene.environment_id} className={Style.Scene}>
            <h4>{parse(scene.title_text)}</h4>
            <div className={Style.SceneImage}>
              <Analytics
                pageName={location.pathname}
                navId="Home Page"
                componentPageId="Enter Scene"
                componentType="Image"
                url={scene.environment_id}
                customAttributes={{
                  'Scene Name': scene.environment_id,
                }}
              >
                <Link title="Enter Scene" to={scene.environment_id}>
                  <img src={scene.image} alt={scene.title_text} />
                </Link>
              </Analytics>
            </div>
            <div className={Style.SceneButton}>
              <Analytics
                pageName={location.pathname}
                navId="Home Page"
                componentPageId="Enter Scene"
                componentType="Button"
                url={scene.environment_id}
                customAttributes={{
                  'Scene Name': scene.environment_id,
                }}
              >
                <Link
                  title="Enter Scene"
                  className={[Style.Button, Style.ButtonRed]
                    .filter((c) => c)
                    .join(' ')}
                  to={scene.environment_id}
                >
                  {scene.enter_button_text}
                </Link>
              </Analytics>
            </div>
          </div>
        ))}
      </div>
      <div className={Style.BackgroundGraphic}>
        <img src={data.background_image} alt="" />
      </div>
    </div>
  );
};

export default HomePage;
