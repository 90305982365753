import { lazy } from 'react';
import { Redirect } from 'react-router-dom';
import HomePage from '../Home/HomePage';
// import Loader from '../Loader/Loader';

const PharmacyPage = lazy(() => import('../Booth/PharmacyPage'));
const ShowcasePage = lazy(() => import('../Booth/ShowcasePage'));

const routes = [
  // {
  //   path: '/loader',
  //   exact: true,
  //   name: 'Loader Test',
  //   component: Loader,
  //   params: {
  //     isAuth: false,
  //   },
  // },
  {
    path: '/',
    exact: true,
    name: 'Home',
    component: HomePage,
    params: {
      isAuth: false,
    },
  },
  {
    path: '/Pharmacy',
    exact: true,
    name: 'Pharmacy',
    component: PharmacyPage,
    params: {
      isAuth: false,
    },
  },
  {
    path: '/Showcase',
    exact: true,
    name: 'Showcase',
    component: ShowcasePage,
    params: {
      isAuth: false,
    },
  },
];

if (
  process.env.NODE_ENV === 'development' ||
  process.env.REACT_APP_DEV_DEBUG === 'true'
) {
  const routeVersion = {
    path: '/version',
    exact: true,
    name: 'Version for QA',
    component: () => {
      return <h1>{process.env.REACT_APP_VERSION}</h1>;
    },
    params: {
      isAuth: false,
    },
  };

  routes.push(routeVersion);
}

const route404 = {
  path: '/*',
  name: 'Not Found',
  component: () => {
    return (
      <Redirect
        to={{
          pathname: '/',
        }}
      />
    );
  },
  params: {
    isAuth: false,
  },
};

// needs to go last in <Switch> list
routes.push(route404);

export default routes;
